/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RiskSummaryTopic {
  FINANCIAL_CRIME = "financial_crimes",
  ESG = "esg",
  PROCEEDINGS = "proceedings",
  OTHER_FLAGS = "other_flags",
  SANCTIONS = "sanctions",
  WATCHLISTS = "watchlists",
  PEPS = "peps"
}
